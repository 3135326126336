import type { ElementData } from '@wix/platform-editor-sdk';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import {
  type ElementsPanelParams,
  openElementsPanel,
} from '../../blocks/common/elements-panel';
import type { EditorAppContext } from '../../types/editor-app-context.type';
import { POST_PAGE_SELECTORS } from './post-page-constants';

const COMPS_DATA = {
  postHeader: { role: POST_PAGE_SELECTORS.POST_HEADER, label: 'Header' },
  postContent: {
    role: POST_PAGE_SELECTORS.POST_CONTENT,
    label: 'Content',
  },
  postFooter: { role: POST_PAGE_SELECTORS.POST_FOOTER, label: 'Footer' },
  relatedPosts: {
    role: POST_PAGE_SELECTORS.RELATED_POSTS,
    label: 'Related Posts',
  },
  comments: {
    role: POST_PAGE_SELECTORS.COMMENTS,
    label: 'Comments',
  },
};

export const openPostPageElementsPanel = async (
  context: EditorAppContext,
  widgetRef: ElementsPanelParams['widgetRef'],
) => {
  const isCommentsEnabled =
    context.flowAPI.experiments.enabled(
      EXPERIMENTS.ENABLE_COMMENTS_INSTALLATION,
    ) || context.flowAPI.experiments.enabled(EXPERIMENTS.NEW_POST_PAGE);

  const elements: ElementData[] = [
    {
      label: COMPS_DATA.postHeader.label,
      identifier: { role: COMPS_DATA.postHeader.role },
      index: 0,
    },
    {
      label: COMPS_DATA.postContent.label,
      identifier: { role: COMPS_DATA.postContent.role },
      index: 1,
    },
    {
      label: COMPS_DATA.postFooter.label,
      identifier: { role: COMPS_DATA.postFooter.role },
      index: 2,
    },
    {
      label: COMPS_DATA.relatedPosts.label,
      identifier: { role: COMPS_DATA.relatedPosts.role },
      index: 3,
    },
  ];

  if (isCommentsEnabled) {
    elements.push({
      label: COMPS_DATA.comments.label,
      identifier: { role: COMPS_DATA.comments.role },
      index: 4,
    });
  }

  return openElementsPanel(context, {
    widgetRef,
    categoriesData: [],
    elementsData: elements,
  });
};
