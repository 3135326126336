import type { EditorSDK } from '@wix/platform-editor-sdk';
import { blogCommentsWidgetId } from '../../external/common/constants/apps';
import {
  HEADER_COMPS_DATA,
  FOOTER_COMPS_DATA,
  CATEGORY_IDS,
} from './constants';

export enum PanelPlacementEnum {
  HEADER = 'header',
  FOOTER = 'footer',
}

type GetCountersElementsParams = {
  sdk: EditorSDK;
  token: string;
  placement: PanelPlacementEnum;
};

const checkIfCommentsWidgetInstalled = async ({
  sdk,
  token,
}: Pick<GetCountersElementsParams, 'sdk' | 'token'>) => {
  try {
    const slots = await sdk.document.tpa.widgetPlugins.getWidgetSlots(token, {
      widgetId: blogCommentsWidgetId,
    });

    /*
     * Once widget is installed `getWidgetSlots` will return
     * an array of slots available for the widget.
     * If the slots array is empty that means widget is already occupied it aka it's installed.
     **/
    return slots.length === 0;
  } catch {
    // 🤫 silently fail if the widget is not installed
    return false;
  }
};

export const getCountersElements = async ({
  sdk,
  token,
  placement,
}: GetCountersElementsParams) => {
  const isCommentsWidgetInstalled = await checkIfCommentsWidgetInstalled({
    sdk,
    token,
  });

  const COMPS_DATA =
    placement === PanelPlacementEnum.HEADER
      ? HEADER_COMPS_DATA
      : FOOTER_COMPS_DATA;

  const counters = [
    {
      label: COMPS_DATA.likes.label,
      identifier: { role: COMPS_DATA.likes.role },
      categoryId: CATEGORY_IDS.counters,
      index: 0,
    },
    {
      label: COMPS_DATA.views.label,
      identifier: { role: COMPS_DATA.views.role },
      categoryId: CATEGORY_IDS.counters,
      index: 1,
    },
  ];

  if (isCommentsWidgetInstalled) {
    counters.push({
      label: COMPS_DATA.comments.label,
      identifier: { role: COMPS_DATA.comments.role },
      categoryId: CATEGORY_IDS.counters,
      index: 2,
    });
  }

  return counters;
};
